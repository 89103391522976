import React from 'react'
import './CompetenciesList.scss'
import { Link } from 'react-router-dom'
import CompetenciesItem from './CompetenciesItem'

// import { Helmet } from 'react-helmet'

function CompetenciesList({
    competenciesData,
    sidebarWidth,
    showBigSidebar,
    handleArrowClick,
    isArrowActive,
    title,
    description,
}) {
    return (
        <div className='CompetenciesList'>
            {/* <Helmet>
                <title>{metaTag.HEADER}</title>
                <meta name='description' content={metaTag.DESCRIPTION} />
                <meta name='keywords' content={metaTag.KEYWORDS} />
            </Helmet> */}
            <section className='left-section'>
                <div className='left-section-container'>
                    <div className='title-container'>
                        <h1 className='page-title'>{title}</h1>
                        <p className='page-description'>{description}</p>
                    </div>
                    <Link to={'/feedback'} className='talk'>
                        Обсудить проект
                    </Link>{' '}
                </div>

                <div className='arrow-container'>
                    <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='18'
                        height='18'
                        viewBox='0 0 18 18'
                        fill='none'
                        stroke='#232323'
                        className={`arrow ${isArrowActive ? 'active' : ''}`}
                        onClick={handleArrowClick}>
                        <path
                            d='M14 4L4 14M4 14L4 4.4M4 14L13.6 14'
                            strokeLinecap='square'
                        />
                    </svg>
                </div>
            </section>
            <section className='right-section'>
                {competenciesData.competencies.map(
                    (competenciesData, index) => (
                        <CompetenciesItem
                            key={competenciesData.ID}
                            competenciesData={competenciesData}
                        />
                    )
                )}
            </section>
        </div>
    )
}

export default CompetenciesList
