import React, { useEffect, useState } from 'react'
import './HeaderMobile.scss'
import { Link } from 'react-router-dom'
import Modal from 'react-modal'

const ScrollToTopOnMount = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return null
}

function HeaderMobile() {
    const [showModal, setShowModal] = useState(false)

    useEffect(() => {
        if (showModal) {
            document.body.style.overflow = 'hidden'
        } else {
            document.body.style.overflow = 'auto'
        }

        // Clean up the effect
        return () => {
            document.body.style.overflow = 'auto'
        }
    }, [showModal])

    const openModal = () => {
        setShowModal(true)
    }

    const closeModal = () => {
        setShowModal(false)
    }

    return (
        <>
            {!showModal && (
                <div className='headerMobile'>
                    <ScrollToTopOnMount />
                    <Link to={'/'}>
                        <img
                            src='/img/logoRound.svg'
                            alt='Logo'
                            className='logoRound'
                            onClick={closeModal}
                        />
                    </Link>
                    <img
                        src='/img/burger-menu.svg'
                        alt='Menu'
                        onClick={openModal}
                        className='burgerMenu'
                    />
                </div>
            )}
            {showModal && (
                <div className='menuGroup'>
                    <Modal
                        className='modalWindow'
                        isOpen={showModal}
                        onRequestClose={closeModal}>
                        <div className='modalWindowContainer'>
                            <div className='mobileMenuContainer'>
                                <img
                                    className='menuExitIcons'
                                    src='/img/menu-exit.svg'
                                    alt='Close Menu'
                                    onClick={closeModal}
                                />
                            </div>
                        </div>
                        <div className='modal-content-container'>
                            <div className='title-section'>
                                <div
                                    className='modalTitle'
                                    onClick={closeModal}>
                                    Платформа
                                </div>
                            </div>

                            <div className='contact-section'>
                                <div className='contact-item'>
                                    <svg className='icon-svg'>
                                        <use xlinkHref='/sprite.svg#white-email-icon' />
                                    </svg>
                                    <a
                                        href='mailto:job@platforma.ru'
                                        className='contact-icon-text'
                                        onClick={closeModal}>
                                        job@paltforma.ru
                                    </a>
                                </div>

                                <div className='contact-item'>
                                    <svg className='icon-svg'>
                                        <use xlinkHref='/sprite.svg#white-phone-icon' />
                                    </svg>
                                    <a
                                        href='tel:+79644294178'
                                        className='contact-icon-text'
                                        onClick={closeModal}>
                                        +7 (964) 429‒41‒78 | +7 (4112) 31‒88‒13
                                    </a>
                                </div>
                            </div>

                            <nav className='navigation-section'>
                                <div className='modal-menu-list modal-grid-container'>
                                    <Link
                                        to='/portfolio'
                                        className='modal-menu-item'
                                        onClick={closeModal}>
                                        Портфолио
                                    </Link>
                                    <Link
                                        to='/services'
                                        className='modal-menu-item'
                                        onClick={closeModal}>
                                        Услуги
                                    </Link>
                                    <Link
                                        to='/competencies'
                                        className='modal-menu-item'
                                        onClick={closeModal}>
                                        Компетенции
                                    </Link>
                                    <Link
                                        to='/about'
                                        className='modal-menu-item'
                                        onClick={closeModal}>
                                        О нас
                                    </Link>
                                    <Link
                                        to='/career'
                                        className='modal-menu-item'
                                        onClick={closeModal}>
                                        Карьера
                                    </Link>
                                    {/* <Link
                                        to='#'
                                        className='modal-menu-item'
                                        onClick={closeModal}>
                                        Битрикс24
                                    </Link> */}
                                    <Link
                                        to={'https://support.platforma.bz'}
                                        className='modal-menu-item'
                                        onClick={closeModal}>
                                        Тех.Поддержка
                                    </Link>
                                </div>
                            </nav>
                            <div className='modal-grid-container-social'>
                                <div className='social-talk'>
                                    <a
                                        href='https://t.me/+79644294178'
                                        className='social-item telegram'
                                        onClick={closeModal}>
                                        <svg className='icon-svg'>
                                            <use href='/sprite.svg#telegram-icon' />
                                        </svg>
                                        Telegram
                                    </a>
                                    <a
                                        href='https://wa.me/+79644294178'
                                        className='social-item whatsapp'
                                        onClick={closeModal}>
                                        <svg className='icon-svg'>
                                            <use href='/sprite.svg#whatsapp-icon' />
                                        </svg>
                                        Whatsapp
                                    </a>
                                    <a
                                        href='https://www.behance.net/'
                                        className='social-item gis'
                                        onClick={closeModal}>
                                        <svg className='icon-svg'>
                                            <use href='/sprite.svg#gis-icon' />
                                        </svg>
                                        2GIS
                                    </a>
                                </div>
                                <Link
                                    to='/feedback'
                                    className='talk-container social-talk'
                                    onClick={closeModal}>
                                    <svg className='icon-svg'>
                                        <use xlinkHref='/sprite.svg#mini-arrow-icon' />
                                    </svg>
                                    <p className='talk-text'>Обсудить проект</p>
                                </Link>
                            </div>

                            <div className={'leftSectionFooter'}>
                                <Link
                                    to='politics'
                                    className={'leftSectionFooterItem'}
                                    onClick={closeModal}>
                                    Политика конфиденциальности
                                </Link>
                                <div
                                    className={'leftSectionFooterItem'}
                                    onClick={closeModal}>
                                    ©2014-2024, ООО «Платформа»
                                </div>
                            </div>
                        </div>
                    </Modal>
                </div>
            )}
        </>
    )
}

export default HeaderMobile
