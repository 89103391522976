import React, { useEffect, useRef, useState } from 'react'
import './PortfolioList.scss'
import PortfolioItem from './PortfolioItem'
import { Helmet } from 'react-helmet'

function PortfolioList({
    portfolioData,
    handleArrowClick,
    isArrowActive,
    title,
    description,
}) {
    const [filter, setFilter] = useState('all')
    const [isActive, setIsActive] = useState(false)
    const textRef = useRef(null)
    const [isMounted, setIsMounted] = useState(false)
    useEffect(() => {
        setIsMounted(true)
    }, [])

    const filterText = {
        all: 'Отобразить все',
        GOV: 'Гос.организации',
        MED: 'Мед.учреждения',
        CORP: 'Корпоративные сайты',
        SHOP: 'Интернет-магазины',
        EDU: 'Обр.учреждения',
    }

    const handleCategoryClick = (category) => {
        setFilter(category)
    }
    const handleClick = () => {
        setIsActive((currentState) => !currentState)
        if (textRef.current) {
            textRef.current.style.height = isActive ? '0' : 'auto'
            textRef.current.style.opacity = isActive ? '0' : '1'
        }
    }
    const filteredCards = portfolioData.portfolios.filter((card) =>
        filter === 'all' ? true : card.ST_TYPE === filter
    )

    console.log(portfolioData, 'portfolioData')

    return (
        <div className='PortfolioList'>
            <Helmet>
                <title>{portfolioData.meta_tags.HEADER}</title>
                <meta
                    name='description'
                    content={portfolioData.meta_tags.DESCRIPTION}
                />
                <meta
                    name='keywords'
                    content={portfolioData.meta_tags.KEYWORDS}
                />
            </Helmet>
            <section className='left-section'>
                <div className='left-section-container'>
                    <div className='title-container'>
                        <h1 className='page-title'>{title}</h1>
                        <p className='page-description'>{description}</p>
                    </div>
                </div>
                <div className='industryGroup'>
                    <div
                        className='contentIndustryContainer'
                        onClick={handleClick}
                        role='button'
                        aria-expanded={isActive}
                        tabIndex={0}>
                        <div className='industryContainerBtnText'>Отрасли</div>
                        <div
                            className={` ${
                                isActive
                                    ? 'industryBtnSvgActive'
                                    : 'industryBtnSvg'
                            }`}>
                            <svg
                                xmlns='http://www.w3.org/2000/svg'
                                width='24'
                                height='25'
                                viewBox='0 0 24 25'
                                fill='none'>
                                <path
                                    d='M19 13.498H13V19.498H11V13.498H5V11.498H11V5.49805H13V11.498H19V13.498Z'
                                    fill='#232323'
                                />
                            </svg>
                        </div>
                    </div>
                    {isActive ? (
                        <div
                            className={` ${
                                isActive
                                    ? 'industryBtnsVisible'
                                    : 'industryBtns'
                            }`}
                            ref={textRef}>
                            <div
                                className='industryBtnText'
                                onClick={() => handleCategoryClick('all')}>
                                {filterText.all}
                            </div>
                            <div
                                className='industryBtnText'
                                onClick={() => handleCategoryClick('GOV')}>
                                {filterText.GOV}
                            </div>
                            <div
                                className='industryBtnText'
                                onClick={() => handleCategoryClick('MED')}>
                                {filterText.MED}
                            </div>
                            <div
                                className='industryBtnText'
                                onClick={() => handleCategoryClick('CORP')}>
                                {filterText.CORP}
                            </div>
                            <div
                                className='industryBtnText'
                                onClick={() => handleCategoryClick('SHOP')}>
                                {filterText.SHOP}
                            </div>
                            <div
                                className='industryBtnText'
                                onClick={() => handleCategoryClick('EDU')}>
                                {filterText.EDU}
                            </div>
                        </div>
                    ) : null}
                </div>
                <div className='arrow-container'>
                    <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='18'
                        height='18'
                        viewBox='0 0 18 18'
                        fill='none'
                        stroke='#232323'
                        className={`arrow ${isArrowActive ? 'active' : ''}`}
                        onClick={handleArrowClick}>
                        <path
                            d='M14 4L4 14M4 14L4 4.4M4 14L13.6 14'
                            strokeLinecap='square'
                        />
                    </svg>
                </div>
            </section>
            <section className='right-section'>
                {filteredCards.map((portfolioData, index) => (
                    <PortfolioItem
                        key={portfolioData.ID}
                        portfolioData={portfolioData}
                        filterText={filterText}
                    />
                ))}
            </section>
        </div>
    )
}

export default PortfolioList
