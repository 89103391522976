import React from 'react'
import './MainList.scss'
import { Link } from 'react-router-dom'
import MainItem from './MainItem'
import { Helmet } from 'react-helmet'

function MainList({
    mainData,
    sidebarWidth,
    showBigSidebar,
    handleArrowClick,
    isArrowActive,
    title,
    description,
}) {
    return (
        <div className='MainList'>
            <Helmet>
                <title>{mainData.meta_tags.HEADER}</title>
                <meta
                    name='description'
                    content={mainData.meta_tags.DESCRIPTION}
                />
                <meta name='keywords' content={mainData.meta_tags.KEYWORDS} />
            </Helmet>
            <section className='left-section'>
                <div className='left-section-container'>
                    <div className='title-container'>
                        <h1 className='page-title'>{title}</h1>
                        <p className='page-description'>{description}</p>
                    </div>
                    <Link to={'/feedback'} className='talk'>
                        Обсудить проект
                    </Link>{' '}
                </div>

                <div className='arrow-container'>
                    <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='18'
                        height='18'
                        viewBox='0 0 18 18'
                        fill='none'
                        stroke='#232323'
                        className={`arrow ${isArrowActive ? 'active' : ''}`}
                        onClick={handleArrowClick}>
                        <path
                            d='M14 4L4 14M4 14L4 4.4M4 14L13.6 14'
                            strokeLinecap='square'
                        />
                    </svg>
                </div>
            </section>
            <section className='right-section'>
                {mainData.main.map((mainData, index) => (
                    <MainItem key={mainData.ID} mainData={mainData} />
                ))}
            </section>
        </div>
    )
}

export default MainList
