import React from "react";
import "./AboutItem.scss";
import { Link } from "react-router-dom";
function AboutItem({ aboutData }) {
  const pathPrefix = window.location.pathname.split("/")[1];

  return (
    <div className='AboutItem-card'>
      <div
        className='card'
        style={{ backgroundColor: aboutData.LOGO.BG_COLOR }}
      >
        <img src={aboutData.LOGO.IMG} alt='LOGO' />
      </div>
      <div
        className='card square-card'
        style={{
          backgroundColor: aboutData.YEARS.BG_COLOR,
          color: aboutData.YEARS.TEXT_COLOR,
        }}
      >
        <p
          className='card-title'
          dangerouslySetInnerHTML={{
            __html: aboutData.YEARS.TITLE,
          }}
        />
        <p className='card-text'>{aboutData.YEARS.CONTENT}</p>
      </div>
      <div
        className='card square-card'
        style={{
          backgroundColor: aboutData.WORKERS.BG_COLOR,
          color: aboutData.WORKERS.TEXT_COLOR,
        }}
      >
        <p
          className='card-title'
          dangerouslySetInnerHTML={{
            __html: aboutData.WORKERS.TITLE,
          }}
        />
        <p className='card-text'>{aboutData.WORKERS.CONTENT}</p>
      </div>
      <div
        className='card'
        style={{
          backgroundColor: aboutData.MAIN_TEXT.BG_COLOR,
          color: aboutData.MAIN_TEXT.TEXT_COLOR,
        }}
      >
        <p className='card-title'>{aboutData.MAIN_TEXT.TITLE}</p>
        <p
          className='card-content'
          dangerouslySetInnerHTML={{
            __html: aboutData.MAIN_TEXT.CONTENT,
          }}
        />
      </div>
      <div
        className='card square-card'
        style={{
          backgroundColor: aboutData.BIG_PROJECTS.BG_COLOR,
          color: aboutData.BIG_PROJECTS.TEXT_COLOR,
        }}
      >
        <p
          className='card-title'
          dangerouslySetInnerHTML={{
            __html: aboutData.BIG_PROJECTS.TITLE,
          }}
        />
        <p className='card-text'>{aboutData.BIG_PROJECTS.CONTENT}</p>
      </div>
      <div
        className='card square-card'
        style={{
          backgroundColor: aboutData.SALES.BG_COLOR,
          color: aboutData.SALES.TEXT_COLOR,
        }}
      >
        <p
          className='card-title'
          dangerouslySetInnerHTML={{
            __html: aboutData.SALES.TITLE,
          }}
        />
        <p className='card-text'>{aboutData.SALES.CONTENT}</p>
      </div>
      <div
        className='card'
        style={{
          backgroundColor: aboutData.ACHIEVS.BG_COLOR,
          color: aboutData.ACHIEVS.TEXT_COLOR,
        }}
      >
        <p className='card-title'>{aboutData.ACHIEVS.TITLE}</p>
        <ul className='card-list'>
          {aboutData.ACHIEVS.CONTENT &&
            aboutData.ACHIEVS.CONTENT.map((item, index) => (
              <li key={index} className='list-item'>
                {item}
              </li>
            ))}
        </ul>
      </div>
      <div
        className='card'
        style={{
          backgroundColor: aboutData.TRUSTS.BG_COLOR,
        }}
      >
        <p
          className='card-title'
          style={{
            color: aboutData.TRUSTS.TEXT_COLOR,
          }}
        >
          {aboutData.TRUSTS.TITLE}
        </p>
      </div>

      {aboutData.TRUSTS.CONTENT.map((cardText, index) => (
        <div
          key={index}
          className='card square-card'
          style={{
            backgroundColor: cardText.BG_COLOR,
          }}
        >
          <div className='row'>
            <p className='card-title' style={{ color: cardText.TEXT_COLOR }}>
              {cardText.NAME}
            </p>
          </div>
          {cardText.IMG && (
            <div
              className='card-img-container-example'
              style={{ background: cardText.BG_COLOR }}
            >
              <img src={cardText.IMG} alt='' />
            </div>
          )}
          <a
            href={cardText.CASE_LINK}
            className='card-link'
            style={{ color: cardText.TEXT_COLOR }}
          >
            Перейти на сайт{" "}
            <svg
              style={{
                stroke: cardText.TEXT_COLOR,
              }}
            >
              <use xlinkHref='/sprite.svg#mini-arrow' />
            </svg>
          </a>
        </div>
      ))}
    </div>
  );
}

export default AboutItem;
