import React from 'react';
import './PortfolioDetailedPageItem.scss';
import he from 'he';
import { Link } from 'react-router-dom';
function PortfolioDetailedPageItem({ portfolio }) {
  const decodedBlocks = portfolio.BLOCKS
    ? portfolio.BLOCKS.map((block) => ({
        ...block,
        CONTENT: block.CONTENT ? he.decode(block.CONTENT) : '',
      }))
    : [];

  return (
    <div className='PortfolioDetailedPageItem'>
      {decodedBlocks.map((block, index) => (
        <div
          className='card'
          key={index}
          style={{ background: block.BG_COLOR }}
        >
          {block.TITLE && (
            <h1 className='card-title' style={{ color: block.TEXT_COLOR }}>
              {block.TITLE}
            </h1>
          )}
          {block.CONTENT && (
            <div
              className='card-text'
              style={{
                color: block.TEXT_COLOR,
              }}
              dangerouslySetInnerHTML={{
                __html: block.CONTENT,
              }}
            />
          )}
          {block.ADVS && (
            <div
              className='card-text'
              style={{
                color: block.TEXT_COLOR,
              }}
              dangerouslySetInnerHTML={{
                __html: block.ADVS,
              }}
            />
          )}
          {block.STACK && (
            <div className='card-stack' style={{ background: block.BG_COLOR }}>
              <h1
                className='card-title'
                style={{
                  color: block.TEXT_COLOR,
                }}
              >
                {block.STACK.TITLE}
              </h1>
              <div className='stack-list'>
                <div className='stack-list-img-container'>
                  {block.STACK.IMG.map((img, index) => (
                    <img key={index} src={img} alt='' />
                  ))}
                </div>
              </div>
            </div>
          )}
          {block.TASKS && (
            <div
              className='card-text'
              style={{
                color: block.TEXT_COLOR,
              }}
              dangerouslySetInnerHTML={{
                __html: block.TASKS,
              }}
            />
          )}

          {block.DESCR && (
            <div
              className='card-text'
              style={{
                color: block.TEXT_COLOR,
              }}
              dangerouslySetInnerHTML={{
                __html: block.DESCR,
              }}
            />
          )}
          {block.IMG && (
            <div
              className='card-img-container'
              style={{ background: block.BG_COLOR }}
            >
              <img src={block.IMG} alt='' />
            </div>
          )}
        </div>
      ))}
      <div
        className='card'
        style={{
          color: '#FFF',
          backgroundColor: '#FF4C00',
        }}
      >
        <p className='card-title'>Расскажите о своем проекте</p>
        <Link to='/feedback' className='goToTalkForm'>
          <p>Написать</p>
        </Link>
      </div>
    </div>
  );
}

export default PortfolioDetailedPageItem;
