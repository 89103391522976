import React from 'react';
import './ServicesDetailedPageItem.scss';
import he from 'he';
import { Link } from 'react-router-dom';

function ServicesDetailedPageItem({ services }) {
  const decodedBlocks = Array.isArray(services?.BLOCKS)
    ? services.BLOCKS.map((block) => ({
        ...block,
        CONTENT: block.CONTENT ? he.decode(block.CONTENT) : '',
      }))
    : [];

  const decodedExamples = Array.isArray(services?.EXAMPLES)
    ? services.EXAMPLES.map((example) => ({
        ...example,
        CONTENT: example.CONTENT ? he.decode(example.CONTENT) : '',
      }))
    : [];

  return (
    <div className='ServicesDetailedPageItem'>
      {decodedBlocks.map((block, index) => (
        <div
          className='card'
          key={index}
          style={{ background: block.BG_COLOR || 'transparent' }} // provide a default if null
        >
          {block.TITLE && (
            <h1 className='card-title' style={{ color: block.TEXT_COLOR }}>
              {block.TITLE}
            </h1>
          )}
          {block.CONTENT && (
            <div
              className='card-text'
              style={{
                color: block.TEXT_COLOR,
              }}
              dangerouslySetInnerHTML={{
                __html: block.CONTENT,
              }}
            />
          )}
          {block.ADVS && (
            <div
              className='card-text'
              style={{
                color: block.TEXT_COLOR,
              }}
              dangerouslySetInnerHTML={{
                __html: block.ADVS,
              }}
            />
          )}
          {block.STACK && (
            <div className='card-stack' style={{ background: block.BG_COLOR }}>
              <h1
                className='card-title'
                style={{
                  color: block.TEXT_COLOR,
                }}
              >
                {block.STACK.TITLE}
              </h1>
              <div className='stack-list'>     {block.STACK.IMG.map((img, index) => (
                <div className='stack-list-img-container'>
             
                    <img key={index} src={img} alt='' />
              </div>    ))}
                
              </div>
            </div>
          )}
          {block.FIRST && (
            <div className='card-first' style={{ background: block.BG_COLOR }}>
              {block.FIRST.TEXT.map((first, index) => (
                <div
                  className='first-card-item'
                  key={index}
                  style={{ color: block.TEXT_COLOR }}
                >
                  {first}
                </div>
              ))}
            </div>
          )}
          {/* {block.ADAPT && (
            <div
              className='card-text'
              style={{
                color: block.TEXT_COLOR,
              }}
              dangerouslySetInnerHTML={{
                __html: block.ADAPT,
              }}
            />
          )} */}
          {block.TASKS && (
            <div
              className='card-text'
              style={{
                color: block.TEXT_COLOR,
              }}
              dangerouslySetInnerHTML={{
                __html: block.TASKS,
              }}
            />
          )}
          {block.DESCR && (
            <div
              className='card-text'
              style={{
                color: block.TEXT_COLOR,
              }}
              dangerouslySetInnerHTML={{
                __html: block.DESCR,
              }}
            />
          )}
          {block.IMG && (
            <div
              className='card-img-container'
              style={{ background: block.BG_COLOR }}
            >
              <img src={block.IMG} alt='' />
            </div>
          )}
        </div>
      ))}

      {decodedExamples.map((example, index) => {
        const isLastCard = index === decodedExamples.length - 1;
        return (
          <div
            // className='card-example'
            className={`card-example ${
              isLastCard && decodedExamples.length % 2 !== 0 ? 'span-two' : ''
            }`}
            key={index}
            style={{
              background: example.BG_COLOR || 'transparent', // provide a default if null
            }}
          >
            <div className='card-example-row'>
              {example.TITLE && (
                <h1
                  className='card-title'
                  style={{
                    color: example.TEXT_COLOR,
                  }}
                >
                  {example.TITLE}
                </h1>
              )}
              {example.IMG && <img src={example.IMG} alt='' />}{' '}
            </div>
            {example.CONTENT && (
              <h1
                className='card-text'
                style={{
                  color: example.TEXT_COLOR,
                }}
              >
                {example.CONTENT}
              </h1>
            )}
          </div>
        );
      })}
      <div
        className='card'
        style={{
          color: '#FFF',
          backgroundColor: '#FF4C00',
        }}
      >
        <p className='card-title'>Расскажите о своем проекте</p>
        <Link to='/feedback' className='goToTalkForm'>
          <p>Написать</p>
        </Link>
      </div>
    </div>
  );
}

export default ServicesDetailedPageItem;
