import React, { useEffect } from 'react'
import './SupportList.scss'
import SupportItem from './SupportItem'

function SupportList({ handleArrowClick, isArrowActive, title, description }) {
    return (
        <div className='SupportList'>
            <section className='left-section'>
                <div className='left-section-container'>
                    <div className='title-container'>
                        <h1 className='page-title'>{title}</h1>
                        <p className='page-description'>{description}</p>
                        <p className='page-description-bold'>
                            Или звоните по номеру <br />
                            <a href='tel:+79644294178'>+7 (964) 429‒41‒78,</a>
                            <br />
                            <a href='tel:+74112318813'>+7 (4112) 31‒88‒13</a>
                        </p>
                    </div>
                </div>
                <div className='arrow-container'>
                    <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='18'
                        height='18'
                        viewBox='0 0 18 18'
                        fill='none'
                        stroke='#232323'
                        className={`arrow ${isArrowActive ? 'active' : ''}`}
                        onClick={handleArrowClick}>
                        <path
                            d='M14 4L4 14M4 14L4 4.4M4 14L13.6 14'
                            strokeLinecap='square'
                        />
                    </svg>
                </div>
            </section>
            {/**rightContentSection */}
            <section className='right-section'>
                <SupportItem />
            </section>
        </div>
    )
}

export default SupportList
