import React, { useState } from 'react'
import './FeedbackItem.scss'
import { InputMask } from 'primereact/inputmask'
import FeedbackFormInBitrix from './FeedbackFormInBitrix'
function FeedbackItem() {
    return (
        <div className='FeedbackItem'>
            <div className='feedbackTitleContainer'>
                <h1 className='feedback-title'>Форма</h1>
            </div>
            <form className='feedbackForm'>
                <FeedbackFormInBitrix />
            </form>
        </div>
    )
}

export default FeedbackItem
