import React, { useEffect } from "react";
import "./PortfolioDetailedPageList.scss";
import PortfolioDetailedPageItem from "./PortfolioDetailedPageItem";
import { Link, useNavigate, useParams, Navigate } from "react-router-dom";
import { Helmet } from "react-helmet";

function PortfolioDetailedPageList({
  handleArrowClick,
  isArrowActive,
  portfolioData,
  setSelectPortfolio,
  portfolioPageTitle,
}) {
  const { id } = useParams();
  const selectPortfolio = Array(
    portfolioData.portfolios.find((caseItem) => caseItem.ID === id)
  );

  useEffect(() => {
    if (selectPortfolio) {
      setSelectPortfolio(selectPortfolio); // обновляем состояние в App
    }
  }, []);

  const navigate = useNavigate();
  const goBack = () => {
    navigate(-1);
  };
  if (!selectPortfolio) {
    return <Navigate to='*' />;
  }

  // console.log(selectPortfolio[0].CASE_LINK, 'selectPortfolio')
  return (
    <div className='PortfolioDetailedPageList'>
      {" "}
      <Helmet>
        <title>{selectPortfolio[0].meta_tags.HEADER}</title>
        <meta
          name='description'
          content={selectPortfolio[0].meta_tags.DESCRIPTION}
        />
        <meta name='keywords' content={selectPortfolio[0].meta_tags.KEYWORDS} />
      </Helmet>
      <section className='left-section'>
        {" "}
        <Link onClick={goBack} className='goBackBtn'>
          Назад
        </Link>
        <div className='left-section-container'>
          <div className='title-container'>
            <h1 className='page-title'>{portfolioPageTitle}</h1>
          </div>
          <Link to={selectPortfolio[0].CASE_LINK} className='goToWebBtn'>
            <div className='talkBtnText'>Перейти на сайт</div>
          </Link>
        </div>
        <div className='arrow-container'>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            width='18'
            height='18'
            viewBox='0 0 18 18'
            fill='none'
            stroke='#232323'
            className={`arrow ${isArrowActive ? "active" : ""}`}
            onClick={handleArrowClick}
          >
            <path
              d='M14 4L4 14M4 14L4 4.4M4 14L13.6 14'
              strokeLinecap='square'
            />
          </svg>
        </div>
      </section>
      <section className='right-section'>
        {selectPortfolio.map((portfolio) => (
          <PortfolioDetailedPageItem key={portfolio.ID} portfolio={portfolio} />
        ))}
      </section>
    </div>
  );
}

export default PortfolioDetailedPageList;
