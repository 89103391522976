import React, { useState } from "react";
import "./ServicesList.scss";
import ServicesItem from "./ServicesItem";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

function ServicesList({
  servicesData,
  handleArrowClick,
  isArrowActive,
  title,
  description,
}) {
  const [filter, setFilter] = useState("all");
  const [isActive, setIsActive] = useState(false);

  const filterText = {
    all: "Отобразить все",
    development: "Разработка и тестирование",
    bitrix: "Внедрение СРМ Б24",
    support: "Тех. поддержка и обновление",
    promotion: "Продвижение и реклама",
    analyst: "Аналитика и проектирование",
    design: "UX/UI - дизайн",
  };

  const handleCategoryClick = (category) => {
    setFilter(category);
  };
  const handleClick = () => {
    setIsActive((currentState) => !currentState);
  };
  const filteredCards = servicesData.services.filter((card) =>
    filter === "all" ? true : card.CATEGORY === filter
  );

  return (
    <div className='ServicesList'>
      <Helmet>
        <title>{servicesData.meta_tags.HEADER}</title>
        <meta name='description' content={servicesData.meta_tags.DESCRIPTION} />
        <meta name='keywords' content={servicesData.meta_tags.KEYWORDS} />
      </Helmet>
      <section className='left-section'>
        <div className='left-section-container'>
          <div className='title-container'>
            <h1 className='page-title'>{title}</h1>
            <p className='page-description'>{description}</p>
          </div>
          <Link to={"/feedback"} className='talk'>
            Обсудить проект
          </Link>{" "}
        </div>
        {/* <div className='industryGroup'>
                    <div
                        className='contentIndustryContainer'
                        onClick={handleClick}
                        role='button'
                        aria-expanded={isActive}
                        tabIndex={0}>
                        <div className='industryContainerBtnText'>
                            Фильтрация
                        </div>
                        <div
                            className={` ${
                                isActive
                                    ? 'industryBtnSvgActive'
                                    : 'industryBtnSvg'
                            }`}>
                            <svg
                                xmlns='http://www.w3.org/2000/svg'
                                width='24'
                                height='25'
                                viewBox='0 0 24 25'
                                fill='none'>
                                <path
                                    d='M19 13.498H13V19.498H11V13.498H5V11.498H11V5.49805H13V11.498H19V13.498Z'
                                    fill='#232323'
                                />
                            </svg>
                        </div>
                    </div>
                    {isActive ? (
                        <div
                            className={` ${
                                isActive
                                    ? 'industryBtnsVisible'
                                    : 'industryBtns'
                            }`}>
                            <div
                                className='industryBtnText'
                                onClick={() => handleCategoryClick('all')}>
                                {filterText.all}
                            </div>
                            <div
                                className='industryBtnText'
                                onClick={() =>
                                    handleCategoryClick('development')
                                }>
                                {filterText.development}
                            </div>
                            <div
                                className='industryBtnText'
                                onClick={() => handleCategoryClick('bitrix')}>
                                {filterText.bitrix}
                            </div>
                            <div
                                className='industryBtnText'
                                onClick={() => handleCategoryClick('support')}>
                                {filterText.support}
                            </div>
                            <div
                                className='industryBtnText'
                                onClick={() =>
                                    handleCategoryClick('promotion')
                                }>
                                {filterText.promotion}
                            </div>
                            <div
                                className='industryBtnText'
                                onClick={() => handleCategoryClick('analyst')}>
                                {filterText.analyst}
                            </div>
                            <div
                                className='industryBtnText'
                                onClick={() => handleCategoryClick('design')}>
                                {filterText.design}
                            </div>
                        </div>
                    ) : null}
                </div> */}
        <div className='arrow-container'>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            width='18'
            height='18'
            viewBox='0 0 18 18'
            fill='none'
            stroke='#232323'
            className={`arrow ${isArrowActive ? "active" : ""}`}
            onClick={handleArrowClick}
          >
            <path
              d='M14 4L4 14M4 14L4 4.4M4 14L13.6 14'
              strokeLinecap='square'
            />
          </svg>
        </div>
      </section>
      <section className='right-section'>
        {filteredCards.map((servicesData, index) => (
          <ServicesItem
            key={servicesData.ID}
            servicesData={servicesData}
            filterText={filterText}
          />
        ))}
      </section>
    </div>
  );
}

export default ServicesList;
