import React, { useEffect } from "react";
import "./CompetenciesDetailPageList.scss";
import CompetenciesDetailPageItem from "./CompetenciesDetailPageItem";
import { Link, useNavigate, useParams, Navigate } from "react-router-dom";
import { Helmet } from "react-helmet";
function CompetenciesDetailPageList({
  handleArrowClick,
  isArrowActive,
  competenciesData,
  setSelectCompetencies,
  competenciesPageTitle,
}) {
  const { id } = useParams();
  const selectCompetencies = Array(
    competenciesData.competencies.find((caseItem) => caseItem.ID === id)
  );
  useEffect(() => {
    if (selectCompetencies) {
      setSelectCompetencies(selectCompetencies);
    }
  }, []);

  const navigate = useNavigate();
  const goBack = () => {
    navigate(-1);
  };
  if (!selectCompetencies) {
    return <Navigate to='*' />;
  }
  // console.log(selectCompetencies, 'selectCompetencies')
  return (
    <div className='CompetenciesDetailPageList'>
      <Helmet>
        <title>{selectCompetencies[0].meta_tags.HEADER}</title>
        <meta
          name='description'
          content={selectCompetencies[0].meta_tags.DESCRIPTION}
        />
        <meta
          name='keywords'
          content={selectCompetencies[0].meta_tags.KEYWORDS}
        />
      </Helmet>{" "}
      <section className='left-section'>
        {" "}
        <Link onClick={goBack} className='goBackBtn'>
          Назад
        </Link>
        <div className='left-section-container'>
          <div className='title-container'>
            <h1 className='page-title'>{competenciesPageTitle}</h1>
          </div>
          <Link to={"/feedback"} className='talk'>
            Обсудить проект
          </Link>{" "}
        </div>
        <div className='arrow-container'>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            width='18'
            height='18'
            viewBox='0 0 18 18'
            fill='none'
            stroke='#232323'
            className={`arrow ${isArrowActive ? "active" : ""}`}
            onClick={handleArrowClick}
          >
            <path
              d='M14 4L4 14M4 14L4 4.4M4 14L13.6 14'
              strokeLinecap='square'
            />
          </svg>
        </div>
      </section>
      <section className='right-section'>
        {selectCompetencies.map((competency, index) => (
          <CompetenciesDetailPageItem
            key={competency.ID}
            selectCompetencies={selectCompetencies}
            competency={competency}
          />
        ))}
      </section>
    </div>
  );
}

export default CompetenciesDetailPageList;
