import React from 'react'
import './ErrorPage.scss'
import { Link, useNavigate } from 'react-router-dom'

function ErrorPage() {
    const navigate = useNavigate()
    const goBack = () => {
        navigate(-1)
    }
    return (
        <div
            className='errorPage'
            style={{
                width: '100vw',
                minHeight: '1040px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                gap: '20px',
                backgroundColor: '#fff',
                borderRadius: '48px',
            }}>
            <div className='container'>
                <h1 className='errorCode'>404</h1>
            </div>
            <Link onClick={goBack} className='goBackDiv'>
                <button className='goBackBtn' type='button'>
                    Вернуться назад
                </button>
            </Link>{' '}
            <h1 className='errorText'>Страница не найдена</h1>
        </div>
    )
}

export default ErrorPage
