import React from 'react'
import './CompetenciesItem.scss'
import { Link } from 'react-router-dom'
function CompetenciesItem({ competenciesData }) {
    const pathPrefix = window.location.pathname.split('/')[1]
    const isCompetenciesPage = pathPrefix === 'competencies'
    return (
        <Link
            to={`/${
                competenciesData.LINK ? competenciesData.LINK : pathPrefix
            }/${competenciesData.ID}`}
            className='CompetenciesItem-card'
            style={{ background: competenciesData.BG_COLOR }}>
            {/************/}
            {competenciesData.IMG && (
                <div className='card-img-container'>
                    <img src={competenciesData.IMG} alt='' />
                </div>
            )}
            {/************/}
            {competenciesData.HASTAG && (
                <div className='card-hashtag-container'>
                    <div
                        className='card-hashtag'
                        style={{ color: competenciesData.TEXT_COLOR }}>
                        {competenciesData.HASTAG}
                    </div>
                </div>
            )}
            {/************/}
            {competenciesData.NAME && (
                <div className='card-title-container'>
                    <h1
                        className='card-title'
                        style={{ color: competenciesData.TEXT_COLOR }}>
                        {competenciesData.NAME}
                    </h1>
                </div>
            )}
            {/************/}
            {competenciesData.DATE && (
                <p
                    className='cardDate'
                    style={{ color: competenciesData.TEXT_COLOR }}>
                    {competenciesData.DATE}
                </p>
            )}
            {/************/}
            {competenciesData.DESCR && (
                <p
                    className='cardDescription'
                    style={{ color: competenciesData.TEXT_COLOR }}>
                    {competenciesData.DESCR}
                </p>
            )}
            {/************/}
        </Link>
    )
}

export default CompetenciesItem
