import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import './EllipseRound.scss'

function EllipseRound() {
    const [visible, setVisible] = useState(true)

    const handleClick = () => {
        setVisible(!visible)
    }

    return (
        <>
            {visible ? (
                <div className='ellipseContainer'>
                    <div className='btnsContainer'>
                        <div
                            className='ellipseRoundContainer'
                            onClick={handleClick}>
                            <img
                                src='/img/Ellipse-811.gif'
                                className='ellipseRoundImg'
                                alt='round'
                                onClick={handleClick}
                            />
                        </div>
                    </div>
                </div>
            ) : (
                <div className='ellipseContainer' onClick={handleClick}>
                    <div className='btnsContainer'>
                        {' '}
                        <div className='ellipseRoundContainer'>
                            <img
                                src='/img/Ellipse-811.gif'
                                className='ellipseRoundImg'
                                alt='round'
                                // onClick={handleClick}
                            />
                        </div>
                        <Link to={'/feedback'} className='talkBtn'>
                            <p className='talkBtnText'>Обсудить проект</p>
                        </Link>{' '}
                        <div className='ellipseRoundContainer'>
                            {' '}
                            <a href='tel:+79991234567'>
                                <img
                                    src='/img/Call-me.svg'
                                    alt='call'
                                    className='ellipseRoundImg'
                                />
                            </a>
                        </div>
                    </div>
                </div>
            )}
        </>
    )
}

export default EllipseRound
