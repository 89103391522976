import React from 'react'
import './PrivacyPolicy.scss'

function PrivacyPolicy() {
    return (
        <div className='PrivacyPolicy'>
            <section>
                <h1 className='PrivacyPolicyTitle'>
                    Политика конфиденциальности и согласие на обработку
                    персональных данных
                </h1>
                <p className='PrivacyPolicyText'>
                    Настоящим в соответствии с Федеральным законом № 152-ФЗ «О
                    персональных данных» от 27.07.2006 года свободно, своей
                    волей и в своем интересе выражаю свое безусловное согласие
                    на обработку моих персональных данных ИП АЛЕКСАНДР
                    ДЬЯЧКОВСКИЙ (ОГРНИП 314144702700065, ИНН 143522011907),
                    зарегистрированным в соответствии с законодательством РФ по
                    адресу:ул. 202-й микрорайон, 15, Якутск, Республика Саха
                    (Я), Россия, 677000 (далее по тексту - Оператор).
                    <br /> <br />
                    Персональные данные - любая информация, относящаяся к
                    определенному или определяемому на основании такой
                    информации физическому лицу.Настоящее Согласие выдано мною
                    на обработку следующих персональных данных: <br />
                    - Имя;
                    <br />
                    - Фамилия;
                    <br />
                    -Отчество;
                    <br />
                    - Телефон;
                    <br />
                    - E-mail;
                    <br />
                    - Адрес проживания.
                    <br /> <br />
                    Согласие дано Обществу для совершения следующих действий с
                    моими персональными данными с использованием средств
                    автоматизации и/или без использования таких средств: сбор,
                    систематизация, накопление, хранение, уточнение (обновление,
                    изменение), использование, обезличивание, передача третьим
                    лицам для указанных ниже целей, а также осуществление любых
                    иных действий, предусмотренных действующим законодательством
                    РФ как неавтоматизированными, так и автоматизированными
                    способами.Данное согласие дается
                    <br /> <br />
                    Оператору и третьему лицу Обществу с ограниченной
                    ответственностью «1С-Битрикс» (ОГРН 5077746476209,
                    ИНН7717586110), зарегистрированному в соответствии с
                    законодательством РФ по адресу: 109544, г. Москва, б-р
                    Энтузиастов, д. 2, 13 эт., пом. 8-19, для обработки моих
                    персональных данных в следующих целях:- сбор сведений об
                    спросе на услуги по подключению интернет провайдера ПАО
                    Ростелеком- предоставление мне услуг/работ/товаров;-
                    направление в мой адрес уведомлений, касающихся
                    предоставляемых услуг/работ/товаров;- подготовка и
                    направление ответов на мои запросы;Настоящее согласие
                    действует до момента его отзыва путем направления
                    соответствующего уведомления на электронный адрес
                    nelno@mail.ru.В случае отзыва мною согласия на обработку
                    персональных данных Оператор вправе продолжить обработку
                    персональных данных без моего согласия при наличии
                    оснований, указанных в пунктах 2 – 11 части 1 статьи 6,
                    части 2 статьи 10 и части 2статьи 11 Федерального закона
                    No152-ФЗ «О персональных данных» от 26.06.2006 г.
                </p>
            </section>
        </div>
    )
}

export default PrivacyPolicy
