import React from 'react';
import './Sidebar.scss';
import { Link, useLocation, useParams } from 'react-router-dom';
import SidebarBanners from '../SidebarBanners/SidebarBanners';

function Sidebar({
  sidebarWidth,
  showBigSidebar,
  handleArrowClick,
  isArrowActive,
}) {
  const year = new Date().getFullYear();
  const location = useLocation();
  const { id } = useParams();
  const isPortfolioActive =
    location.pathname === '/portfolio' ||
    location.pathname === `/portfolio/${id}` ||
    location.pathname.startsWith('/portfolio/');
  const isServicesActive =
    location.pathname === '/services' ||
    location.pathname === `/services/${id}` ||
    location.pathname.startsWith('/services/');
  const isCompetenciesActive =
    location.pathname === '/competencies' ||
    location.pathname === `/competencies/${id}` ||
    location.pathname.startsWith('/competencies/');
  const isCareerActive =
    location.pathname === '/career' ||
    location.pathname === `/career/${id}` ||
    location.pathname.startsWith('/career/');
  const isAboutActive = location.pathname === '/about';
  const isSupportActive =
    location.pathname === '/support' || location.pathname === '/support/:id';
  return (
    <nav
      className='Sidebar'
      style={{ width: `${sidebarWidth}px` }}
      data-show-big-sidebar={showBigSidebar}
    >
      <div className='sidebar-container'>
        <div className='sidebar-nav'>
          <Link to={'/'} className='logo-container'>
            <svg>
              <use href='/sprite.svg#logo' />
            </svg>
            {showBigSidebar && <p className='logo-text'>ПЛАТФОРМА</p>}
          </Link>
          <div className='sidebar-nav-menu'>
            <Link
              to={'/portfolio'}
              className={`sidebar-item ${isPortfolioActive ? 'active' : ''}`}
            >
              <svg className='portfolio'>
                <use href='/sprite.svg#portfolio' />
              </svg>
              {showBigSidebar && <p className='sidebar-item-text'>Портфолио</p>}
            </Link>
            <Link
              to={'/services'}
              className={`sidebar-item ${isServicesActive ? 'active' : ''}`}
            >
              <svg className='services'>
                <use href='/sprite.svg#services' />
              </svg>
              {showBigSidebar && <p className='sidebar-item-text'>Услуги</p>}
            </Link>
            <Link
              to={'/competencies'}
              className={`sidebar-item ${isCompetenciesActive ? 'active' : ''}`}
            >
              <svg className='competencies'>
                <use href='/sprite.svg#competencies' />
              </svg>
              {showBigSidebar && (
                <p className='sidebar-item-text'>Компетенции</p>
              )}
            </Link>
            <Link
              to={'/about'}
              className={`sidebar-item ${isAboutActive ? 'active' : ''}`}
            >
              <svg className='about'>
                <use href='/sprite.svg#about' />
              </svg>
              {showBigSidebar && <p className='sidebar-item-text'>О нас</p>}
            </Link>
            <Link
              to={'/career'}
              className={`sidebar-item ${isCareerActive ? 'active' : ''}`}
            >
              <svg className='career'>
                <use href='/sprite.svg#career' />
              </svg>
              {showBigSidebar && <p className='sidebar-item-text'>Карьера</p>}
            </Link>
            {/* <Link to={'#'} className='sidebar-item'>
                            <svg className='bitrix'>
                                <use href='/sprite.svg#bitrix' />
                            </svg>
                            {showBigSidebar && (
                                <p className='sidebar-item-text'>
                                    Внедрение Битрикс
                                </p>
                            )}
                        </Link> */}
            <Link
              to={'https://support.platforma.bz'}
              className={`sidebar-item ${isSupportActive ? 'active' : ''}`}
            >
              <svg className='support'>
                <use href='/sprite.svg#support' />
              </svg>
              {showBigSidebar && (
                <p className='sidebar-item-text'>Тех.поддержка</p>
              )}
            </Link>
          </div>
        </div>
        {!showBigSidebar && <div className='sidebar-gray-line-mini'></div>}
        {showBigSidebar && <div className='sidebar-gray-line'></div>}
        <div className='sidebar-contacts'>
          <Link to={'mailto:  job@platforma.ru'} className='sidebar-item'>
            <svg className='email'>
              <use href='/sprite.svg#email' />
            </svg>
            {showBigSidebar && (
              <p className='sidebar-item-text'>job@platforma.ru</p>
            )}
          </Link>{' '}
          <Link to={'tel:+74112318813'} className='sidebar-item'>
            <svg className='phone'>
              <use href='/sprite.svg#phone' />
            </svg>
            {showBigSidebar && (
              <p className='sidebar-item-text'>+7 (4112) 31‒88‒13</p>
            )}
          </Link>
          <Link to={'tel:+79644294178'} className='sidebar-item'>
            <svg className='phone'>
              <use href='/sprite.svg#mobile' />
            </svg>
            {showBigSidebar && (
              <p className='sidebar-item-text'>+7 (964) 429‒41‒78</p>
            )}
          </Link>
        </div>
      </div>
      {/* {showBigSidebar && <SidebarBanners />} */}
      {!showBigSidebar && (
        <div className='sidebar-social-mini'>
          <a
            href='https://t.me/+79644294178'
            className='sidebar-social-item telegram'
          >
            <svg>
              <use href='/sprite.svg#telegram-icon' />
            </svg>
          </a>
          <a
            href='https://wa.me/+79644294178'
            className='sidebar-social-item whatsapp'
          >
            <svg>
              <use href='/sprite.svg#whatsapp-icon' />
            </svg>
          </a>
          <a
            href='https://go.2gis.com/6gpfq'
            className='sidebar-social-item gis'
          >
            <svg>
              <use href='/sprite.svg#gis-icon' />
            </svg>
          </a>
        </div>
      )}
      <div className='sidebar-down'>
        {showBigSidebar && (
          <div className='sidebar-social'>
            <a
              href='https://t.me/+79644294178'
              className='sidebar-social-item telegram'
            >
              <svg>
                <use href='/sprite.svg#telegram-icon' />
              </svg>

              <div className='sidebar-social-item-text'>Telegram</div>
            </a>
            <a
              href='https://wa.me/+79644294178'
              className='sidebar-social-item whatsapp'
            >
              <svg>
                <use href='/sprite.svg#whatsapp-icon' />
              </svg>

              <div className='sidebar-social-item-text'>Whatsapp</div>
            </a>
            <a
              href='https://go.2gis.com/6gpfq'
              className='sidebar-social-item gis'
            >
              <svg>
                <use href='/sprite.svg#gis-icon' />
              </svg>
              <div className='sidebar-social-item-text'>2GIS</div>
            </a>
          </div>
        )}
        {showBigSidebar && (
          <div className='sidebar-footer'>
            <Link
              to='/politics'
              download={true}
              className='sidebar-footer policy'
            >
              Политика конфиденциальности
            </Link>
            <div className='sidebar-footer'>
              {`© 2014-${year}, ООО «Платформа»`}
            </div>
          </div>
        )}
      </div>
    </nav>
  );
}

export default Sidebar;
