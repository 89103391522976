import React from 'react'
import './ServicesItem.scss'
import { Link } from 'react-router-dom'

function ServicesItem({ servicesData, filterText }) {
    const pathPrefix = window.location.pathname.split('/')[1]
    const isServicesPage = pathPrefix === 'services'
    return (
        <Link
            to={`/${servicesData.LINK ? servicesData.LINK : pathPrefix}/${
                servicesData.ID
            }`}
            className='ServicesItem-card'
            style={{ background: servicesData.BG_COLOR }}>
            {servicesData.IMG && (
                <div className='card-img-container'>
                    <img src={servicesData.IMG} alt='' />
                </div>
            )}
            {servicesData.HASHTAG && (
                <div
                    className='card-hashtag-container'
                    style={{ color: servicesData.TEXT_COLOR }}>
                    <div
                        className='card-hashtag'
                        style={{ color: servicesData.TEXT_COLOR }}>
                        {servicesData.HASHTAG}
                    </div>
                </div>
            )}
            {servicesData.NAME && (
                <div className='card-title-container'>
                    <h1
                        className='card-title'
                        style={{ color: servicesData.TEXT_COLOR }}>
                        {servicesData.NAME}
                    </h1>
                </div>
            )}

            {servicesData.DATE && (
                <p
                    className='cardDate'
                    style={{ color: servicesData.TEXT_COLOR }}>
                    {servicesData.DATE}
                </p>
            )}

            {servicesData.DESCR && (
                <p
                    className='cardDescription'
                    style={{ color: servicesData.TEXT_COLOR }}>
                    {servicesData.DESCR}
                </p>
            )}
        </Link>
    )
}

export default ServicesItem
