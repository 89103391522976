import React from "react";
import "./CompetenciesDetailPageItem.scss";
import he from "he";

function CompetenciesDetailPageItem({
  selectCompetencies,
  competenciesData,
  competency,
}) {
  const decodedBlocks = competency.BLOCKS
    ? competency.BLOCKS.map((block, index) => ({
        ...block,
        CONTENT: block.CONTENT ? he.decode(block.CONTENT) : "",
        key: `${block.ID}-${index}`,
      }))
    : [];

  const decodedExamples = competency.EXAMPLES.CONTENT
    ? competency.EXAMPLES.CONTENT.map((example, index) => ({
        ...example,
        NAME: example.NAME ? he.decode(example.NAME) : "",
        key: `${example.ID}-${index}`,
      }))
    : [];

  return (
    <div className='CompetenciesDetailPageItemContainer'>
      {decodedBlocks.map((block) => (
        <div
          className='card'
          key={block.key}
          style={{ background: block.BG_COLOR }}
        >
          {block.TITLE && (
            <h1 className='card-title' style={{ color: block.TEXT_COLOR }}>
              {block.TITLE}
            </h1>
          )}
          {block.CONTENT && (
            <div
              className='card-text'
              style={{
                color: block.TEXT_COLOR,
              }}
              dangerouslySetInnerHTML={{
                __html: block.CONTENT,
              }}
            />
          )}

          {block.ADVS && (
            <div
              className='card-text'
              style={{
                color: block.TEXT_COLOR,
              }}
              dangerouslySetInnerHTML={{
                __html: block.ADVS,
              }}
            />
          )}

          {block.TASKS && (
            <div
              className='card-text'
              style={{
                color: block.TEXT_COLOR,
              }}
              dangerouslySetInnerHTML={{
                __html: block.TASKS,
              }}
            />
          )}

          {block.DESCR && (
            <div
              className='card-text'
              style={{
                color: block.TEXT_COLOR,
              }}
              dangerouslySetInnerHTML={{
                __html: block.DESCR,
              }}
            />
          )}
          {block.IMG && (
            <div
              className='card-img-container'
              style={{ background: block.BG_COLOR }}
            >
              <img src={block.IMG} alt='' />
            </div>
          )}
        </div>
      ))}
      {decodedExamples.map((example, index) => {
        const isLastCard = index === decodedExamples.length - 1;
        return (
          <div
            className={`card-example ${
              isLastCard && decodedExamples.length % 2 !== 0 ? "span-two" : ""
            }`}
            key={example.key}
            style={{
              background: example.BG_COLOR,
            }}
          >
            {example.NAME && (
              <h1 className='card-title' style={{ color: example.TEXT_COLOR }}>
                {example.NAME}
              </h1>
            )}
            {example.IMG && (
              <div
                className='card-img-container-example'
                style={{ background: example.BG_COLOR }}
              >
                <img src={example.IMG} alt='' />
              </div>
            )}
            {example.CASE_LINK && (
              <a
                href={example.CASE_LINK}
                className='card-link'
                style={{ color: example.TEXT_COLOR }}
              >
                Перейти на сайт{" "}
                <svg
                  style={{
                    stroke: example.TEXT_COLOR,
                  }}
                >
                  <use href='/sprite.svg#mini-arrow' />
                </svg>
              </a>
            )}
          </div>
        );
      })}
      {/* {decodedExamples.map((example) => (
        <div
          className='card-example'
          key={example.key}
          style={{
            background: example.BG_COLOR,
          }}
        >
          {example.NAME && (
            <h1
              className='card-title'
              style={{
                color: example.TEXT_COLOR,
              }}
            >
              {example.NAME}
            </h1>
          )}
          {example.CASE_LINK && (
            <a
              href={example.CASE_LINK}
              className='card-link'
              style={{
                color: example.TEXT_COLOR,
              }}
            >
              Перейти на сайт
              <svg
                style={{
                  color: example.TEXT_COLOR,
                  width: '20px',
                  height: '20px',
                }}
              >
                <use
                  href='/sprite.svg#link-arrow'
                  style={{
                    stroke: example.TEXT_COLOR,
                  }}
                />
              </svg>
            </a>
          )}
        </div>
      ))} */}
    </div>
  );
}

export default CompetenciesDetailPageItem;
