import React, { useState } from 'react'
import './CareerDetailedPageItem.scss'
import { Navigate } from 'react-router'
import { InputMask } from 'primereact/inputmask'
import he from 'he'
import CareerFormInBitrix from './CareerFormInBitrix'

function CareerDetailedPageItem({ career }) {
    const decodedCareer = {
        ...career,
        BLOCK1: {
            ...career.BLOCK1,
            CONTENT: career.BLOCK1.CONTENT
                ? Array.isArray(career.BLOCK1.CONTENT)
                    ? career.BLOCK1.CONTENT.map((item) => he.decode(item))
                    : he.decode(career.BLOCK1.CONTENT)
                : '',
        },
        BLOCK2: {
            ...career.BLOCK2,
            CONTENT: career.BLOCK2.CONTENT
                ? Array.isArray(career.BLOCK2.CONTENT)
                    ? career.BLOCK2.CONTENT.map((item) => he.decode(item))
                    : he.decode(career.BLOCK2.CONTENT)
                : '',
        },
        BLOCK3: {
            ...career.BLOCK3,
            CONTENT: career.BLOCK3.CONTENT
                ? Array.isArray(career.BLOCK3.CONTENT)
                    ? career.BLOCK3.CONTENT.map((item) => he.decode(item))
                    : he.decode(career.BLOCK3.CONTENT)
                : '',
        },
    }

    if (!career) {
        return <Navigate to='*' />
    }

    return (
        <div className='CareerDetailedPageItemContainer'>
            {decodedCareer.BLOCK1.CONTENT && (
                <div
                    className='card'
                    style={{ background: decodedCareer.BLOCK1.BG_COLOR }}>
                    <h3
                        className='card-title'
                        style={{ color: decodedCareer.BLOCK1.TEXT_COLOR }}>
                        {decodedCareer.BLOCK1.TITLE}
                    </h3>
                    {Array.isArray(decodedCareer.BLOCK1.CONTENT) ? (
                        decodedCareer.BLOCK1.CONTENT.map((item, index) => (
                            <div
                                key={index}
                                className='card-text'
                                style={{
                                    color: decodedCareer.BLOCK1.TEXT_COLOR,
                                }}>
                                {item}
                            </div>
                        ))
                    ) : (
                        <p
                            className='card-text'
                            style={{
                                color: decodedCareer.BLOCK1.TEXT_COLOR,
                            }}>
                            {decodedCareer.BLOCK1.CONTENT}
                        </p>
                    )}
                </div>
            )}
            {decodedCareer.BLOCK2.CONTENT && (
                <div
                    className='card'
                    style={{ background: decodedCareer.BLOCK2.BG_COLOR }}>
                    <h3
                        className='card-title'
                        style={{ color: decodedCareer.BLOCK2.TEXT_COLOR }}>
                        {decodedCareer.BLOCK2.TITLE}
                    </h3>
                    {Array.isArray(decodedCareer.BLOCK2.CONTENT) ? (
                        decodedCareer.BLOCK2.CONTENT.map((item, index) => (
                            <div
                                key={index}
                                className='card-text'
                                style={{
                                    color: decodedCareer.BLOCK2.TEXT_COLOR,
                                }}>
                                {item}
                            </div>
                        ))
                    ) : (
                        <p
                            className='card-text'
                            style={{
                                color: decodedCareer.BLOCK2.TEXT_COLOR,
                            }}>
                            {decodedCareer.BLOCK2.CONTENT}
                        </p>
                    )}
                </div>
            )}
            {decodedCareer.BLOCK3.CONTENT && (
                <div
                    className='card'
                    style={{ background: decodedCareer.BLOCK3.BG_COLOR }}>
                    <h3
                        className='card-title'
                        style={{ color: decodedCareer.BLOCK3.TEXT_COLOR }}>
                        {decodedCareer.BLOCK3.TITLE}
                    </h3>
                    {Array.isArray(decodedCareer.BLOCK3.CONTENT) ? (
                        decodedCareer.BLOCK3.CONTENT.map((item, index) => (
                            <div
                                key={index}
                                className='card-text'
                                style={{
                                    color: decodedCareer.BLOCK3.TEXT_COLOR,
                                }}>
                                {item}
                            </div>
                        ))
                    ) : (
                        <p
                            className='card-text'
                            style={{
                                color: decodedCareer.BLOCK3.TEXT_COLOR,
                            }}>
                            {decodedCareer.BLOCK3.CONTENT}
                        </p>
                    )}
                </div>
            )}

            <form action='' className='form'>
                <h2 className='title-form'>Заполните форму</h2>
                <CareerFormInBitrix />
            </form>
        </div>
    )
}

export default CareerDetailedPageItem
