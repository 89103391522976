import React, { useEffect } from "react";
import "./CareerDetailedPageList.scss";
import { Link, useNavigate, useParams, Navigate } from "react-router-dom";
import CareerDetailedPageItem from "./CareerDetailedPageItem";
import { Helmet } from "react-helmet";

function CareerDetailedPageList({
  handleArrowClick,
  isArrowActive,
  careerData,
  setSelectCareer,
  careerPageTitle,
}) {
  const { id } = useParams();
  const selectCareer = Array(
    careerData.career.find((caseItem) => caseItem.ID === id)
  );
  useEffect(() => {
    if (selectCareer) {
      setSelectCareer(selectCareer);
    }
  }, [selectCareer, setSelectCareer]);

  // const card = portfolioData.find((caseItem) => caseItem.ID === '1113')
  const navigate = useNavigate();
  const goBack = () => {
    navigate(-1);
  };
  if (!selectCareer) {
    return <Navigate to='*' />;
  }

  // const isEven = selectPortfolio.length % 2 === 0
  // const lastCardIndex = selectPortfolio.length - 1
  return (
    <div className='CareerDetailedPageList'>
      <Helmet>
        <title>{selectCareer[0].meta_tags.HEADER}</title>
        <meta
          name='description'
          content={selectCareer[0].meta_tags.DESCRIPTION}
        />
        <meta name='keywords' content={selectCareer[0].meta_tags.KEYWORDS} />
      </Helmet>{" "}
      <section className='left-section'>
        <Link onClick={goBack} className='goBackBtn'>
          Назад
        </Link>
        <div className='left-section-container'>
          <div className='title-container'>
            <h1 className='page-title'>{careerPageTitle}</h1>
          </div>
          <p className='page-description-bold'>
            Или звоните по номеру <br />
            <a href='tel:+79644294178'>+7 (964) 429‒41‒78,</a>
            <br />
            <a href='tel:+74112318813'>+7 (4112) 31‒88‒13</a>
          </p>
        </div>

        <div className='arrow-container'>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            width='18'
            height='18'
            viewBox='0 0 18 18'
            fill='none'
            stroke='#232323'
            className={`arrow ${isArrowActive ? "active" : ""}`}
            onClick={handleArrowClick}
          >
            <path
              d='M14 4L4 14M4 14L4 4.4M4 14L13.6 14'
              strokeLinecap='square'
            />
          </svg>
        </div>
      </section>
      <section className='right-section'>
        {selectCareer.map((career, index) => (
          <CareerDetailedPageItem
            key={career.ID}
            selectCareer={selectCareer}
            career={career}
          />
        ))}
      </section>
    </div>
  );
}

export default CareerDetailedPageList;
