import { useEffect, useState } from "react";
import {
  Routes,
  Route,
  Outlet,
  useLocation,
  useParams,
} from "react-router-dom";
import "./App.scss";
import MainList from "../features/Sections/MainPage/MainList";
import PrivacyPolicy from "../features/Politics/PrivacyPolicy";
import ErrorPage from "../features/404/ErrorPage";
import Sidebar from "../features/Sidebar/Sidebar";
import PortfolioList from "../features/Sections/PortfolioPage/PortfolioList";
import ServicesList from "../features/Sections/ServicesPage/ServicesList";
import AboutList from "../features/Sections/AboutPage/AboutList";
import CareerList from "../features/Sections/CareerPage/CareerList";
import { useMediaQuery } from "react-responsive";
import HeaderMobile from "../features/HeaderMobile/HeaderMobile";
import EllipseRound from "../features/EllipseRound/EllipseRound";
import SupportList from "../features/Support/SupportList";
import CompetenciesList from "../features/Sections/CompetenciesPage/CompetenciesList";
import FeedbackList from "../features/Sections/Feedback/FeedbackList";
import PortfolioDetailedPageList from "../features/DetailPages/PortfolioDetail/PortfolioDetailedPageList";
import CompetenciesDetailPageList from "../features/DetailPages/CompetenciesDetail/CompetenciesDetailPageList";
import ServicesDetailedPageList from "../features/DetailPages/ServicesDetail/ServicesDetailedPageList";
import CareerDetailedPageList from "../features/DetailPages/CareerDetail/CareerDetailedPageList";

function App() {
  const [mainData, setMainData] = useState([]);
  const [portfolioData, setPortfolioData] = useState([]);
  const [servicesData, setServicesData] = useState([]);
  const [aboutData, setAboutData] = useState([]);
  const [competenciesData, setCompetenciesData] = useState([]);
  const [careerData, setCareerData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isArrowActive, setIsArrowActive] = useState(false);
  const [sidebarWidth, setSidebarWidth] = useState(320);
  const [showBigSidebar, setShowBigSidebar] = useState(true);
  const location = useLocation();
  const { id } = useParams();
  const [selectPortfolio, setSelectPortfolio] = useState("Portfolio-Detail");
  const [selectServices, setSelectServices] = useState("Services-Detail");
  const [selectCompetencies, setSelectCompetencies] = useState(
    "Competencies-Detail"
  );
  const [selectCareer, setSelectCareer] = useState("Career-Detail");

  const isWidth820 = useMediaQuery({
    query: "(max-width: 820px)",
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Главная страница - живая лента
        const mainResponse = await fetch("https://platforma.bz/api/main");
        if (!mainResponse.ok) {
          throw new Error(
            "Не удалось получить данные Главной страницы" + mainResponse.status
          );
        }
        const mainData = await mainResponse.json();
        // console.log(mainData, 'mainData')

        // Портфолио
        const portfolioResponse = await fetch(
          "https://platforma.bz/api/portfolio"
        );
        if (!portfolioResponse.ok) {
          throw new Error(
            "Не удалось получить данные Портфолио" + portfolioResponse.status
          );
        }
        const portfolioData = await portfolioResponse.json();
        // console.log(portfolioData)

        // Услуги
        const servicesResponse = await fetch(
          "https://platforma.bz/api/services"
        );
        if (!servicesResponse.ok) {
          throw new Error(
            "Не удалось получить данные Услуг" + servicesResponse.status
          );
        }
        const servicesData = await servicesResponse.json();
        // console.log(servicesData)

        // О нас
        const aboutResponse = await fetch("https://platforma.bz/api/about");
        if (!aboutResponse.ok) {
          throw new Error("Не удалось получить данные О нас" + aboutResponse);
        }
        const aboutData = await aboutResponse.json();
        // console.log(aboutData)

        //  Компетенции
        const competenciesResponse = await fetch(
          "https://platforma.bz/api/competencies"
        );
        if (!competenciesResponse.ok) {
          throw new Error(
            "Не удалось получить данные Компетенций" + competenciesResponse
          );
        }
        const competenciesData = await competenciesResponse.json();
        // console.log(competenciesData)

        // Карьера
        const careerResponse = await fetch("https://platforma.bz/api/career");
        if (!careerResponse.ok) {
          throw new Error(
            "Не удалось получить данные Карьеры" + careerResponse
          );
        }
        const careerData = await careerResponse.json();
        // console.log(careerData)

        setMainData(mainData);
        setPortfolioData(portfolioData);
        setServicesData(servicesData);
        setAboutData(aboutData);
        setCompetenciesData(competenciesData);
        setCareerData(careerData);
        setIsArrowActive(false);
        setIsLoading(false);
      } catch (error) {
        console.error("Ошибка получения данных:", error);
        setError(error);
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  if (error) {
    return <div>Произошла ошибка: {error.message}</div>;
  }

  if (isLoading) {
    return (
      <div className='loaderContainer'>
        <div className='loader'></div>
        <p>Загрузка...</p>
      </div>
    );
  }

  const handleArrowClick = () => {
    setIsArrowActive((prev) => !prev);
    setSidebarWidth((prevWidth) => (prevWidth === 128 ? 320 : 128));
    setShowBigSidebar((prev) => !prev);
  };

  const prefix = location.pathname;

  const pageTitles = {
    "/": {
      title: "Поднимаем бизнес на новый уровень цифровой эры",
      description:
        "Мы — команда, заинтересованная в развитии сферы цифровых технологий.",
    },
    "/portfolio": {
      title: "Лучшие работы нашей компании",
      description:
        "Мы — команда, заинтересованная в развитии сферы цифровых технологий. ",
    },
    "/services": {
      title: "Начните свой проект уже сегодня",
      description:
        "«Платформа» — идеальная площадка для будущих IT-специалистов набрать реальный опыт на пути к будущей профессии",
    },
    "/about": {
      title: "Платформа - Скачок",
      description:
        "Диджитал-агентство полного цикла. \n\rМы — команда, заинтересованная в развитии сферы цифровых технологий. Именно поэтому с уверенностью отвечаем за внедряемые нами решения",
    },
    "/support": {
      title: "Техническая поддержка",
      description: "Сайты в надежных руках: ваш успех - наша забота.",
    },
    "/career": {
      title: "Карьера в нашей компании",
      description:
        "«Платформа» — идеальная площадка для будущих IT-специалистов набрать реальный опыт на пути к будущей профессии",
    },
    "/competencies": {
      title: "Компетенции",
      description:
        "Мы — команда, заинтересованная в развитии сферы цифровых технологий. ",
    },

    "/feedback": {
      title: "Какой проект вы хотите?",
      description: "Заполните форму обратной связи и мы свяжемся с вами",
    },
  };

  const detailPageTitles = {
    "/portfolio/:id": {
      detailTitle: selectPortfolio[0].NAME || "Portfolio-Detail",
    },
    "/services/:id": {
      detailTitle: selectServices[0].NAME || "Services-Detail",
    },
    "/about/:id": {
      detailTitle: "About-Detail",
    },
    "/competencies/:id": {
      detailTitle: selectCompetencies[0].NAME || "Competencies-Detail",
    },
    "/career/:id": {
      detailTitle: selectCareer[0].NAME || "Career-Detail",
    },
  };

  const { title, description } = pageTitles[prefix] ||
    pageTitles["/:id"] || { title: "", description: "" };

  const { detailTitle, detailLink } = detailPageTitles[prefix] ||
    detailPageTitles["/:id"] || {
      detailTitle: "",
      detailLink: "",
    };
  const portfolioPageTitle = detailPageTitles["/portfolio/:id"].detailTitle;
  const servicesPageTitle = detailPageTitles["/services/:id"].detailTitle;
  const competenciesPageTitle =
    detailPageTitles["/competencies/:id"].detailTitle;
  const careerPageTitle = detailPageTitles["/career/:id"].detailTitle;

  return (
    <>
      <div className='App'>
        {isWidth820 && (
          <>
            {" "}
            <HeaderMobile />
            <div className='title-container'>
              <h1 className='page-title'>{title}</h1>
              <p className='page-description'>{description}</p>
            </div>
            <div className='ellipse-container'>
              <EllipseRound />
            </div>
          </>
        )}
        <Sidebar
          handleArrowClick={handleArrowClick}
          isArrowActive={isArrowActive}
          sidebarWidth={sidebarWidth}
          showBigSidebar={showBigSidebar}
        />
        <Routes>
          <Route
            index
            element={
              <MainList
                mainData={mainData}
                handleArrowClick={handleArrowClick}
                isArrowActive={isArrowActive}
                sidebarWidth={sidebarWidth}
                showBigSidebar={showBigSidebar}
                title={title}
                description={description}
              />
            }
          />
          <Route
            path='/portfolio'
            element={
              <PortfolioList
                portfolioData={portfolioData}
                handleArrowClick={handleArrowClick}
                isArrowActive={isArrowActive}
                sidebarWidth={sidebarWidth}
                showBigSidebar={showBigSidebar}
                title={title}
                description={description}
              />
            }
          />
          <Route
            path='/portfolio/:id'
            element={
              <PortfolioDetailedPageList
                portfolioData={portfolioData}
                handleArrowClick={handleArrowClick}
                isArrowActive={isArrowActive}
                sidebarWidth={sidebarWidth}
                showBigSidebar={showBigSidebar}
                setSelectPortfolio={setSelectPortfolio}
                portfolioPageTitle={portfolioPageTitle}
              />
            }
          />
          <Route
            path='/services'
            element={
              <ServicesList
                servicesData={servicesData}
                handleArrowClick={handleArrowClick}
                isArrowActive={isArrowActive}
                sidebarWidth={sidebarWidth}
                showBigSidebar={showBigSidebar}
                title={title}
                description={description}
              />
            }
          />
          <Route
            path='/services/:id'
            element={
              <ServicesDetailedPageList
                servicesData={servicesData}
                handleArrowClick={handleArrowClick}
                isArrowActive={isArrowActive}
                sidebarWidth={sidebarWidth}
                showBigSidebar={showBigSidebar}
                setSelectServices={setSelectServices}
                servicesPageTitle={servicesPageTitle}
              />
            }
          />
          <Route
            path='/competencies'
            element={
              <CompetenciesList
                competenciesData={competenciesData}
                handleArrowClick={handleArrowClick}
                isArrowActive={isArrowActive}
                sidebarWidth={sidebarWidth}
                showBigSidebar={showBigSidebar}
                title={title}
                description={description}
              />
            }
          />
          <Route
            path='/competencies/:id'
            element={
              <CompetenciesDetailPageList
                isArrowActive={isArrowActive}
                sidebarWidth={sidebarWidth}
                showBigSidebar={showBigSidebar}
                handleArrowClick={handleArrowClick}
                competenciesData={competenciesData}
                setSelectCompetencies={setSelectCompetencies}
                competenciesPageTitle={competenciesPageTitle}
              />
            }
          />
          <Route
            path='/about'
            element={
              <AboutList
                aboutData={aboutData}
                handleArrowClick={handleArrowClick}
                isArrowActive={isArrowActive}
                sidebarWidth={sidebarWidth}
                showBigSidebar={showBigSidebar}
                title={title}
                description={description}
              />
            }
          />
          <Route
            path='/career'
            element={
              <CareerList
                careerData={careerData}
                handleArrowClick={handleArrowClick}
                isArrowActive={isArrowActive}
                sidebarWidth={sidebarWidth}
                showBigSidebar={showBigSidebar}
                title={title}
                description={description}
              />
            }
          />
          <Route
            path='/career/:id'
            element={
              <CareerDetailedPageList
                isArrowActive={isArrowActive}
                sidebarWidth={sidebarWidth}
                showBigSidebar={showBigSidebar}
                handleArrowClick={handleArrowClick}
                careerData={careerData}
                setSelectCareer={setSelectCareer}
                careerPageTitle={careerPageTitle}
              />
            }
          />
          <Route
            path='/support'
            element={
              <SupportList
                handleArrowClick={handleArrowClick}
                isArrowActive={isArrowActive}
                sidebarWidth={sidebarWidth}
                showBigSidebar={showBigSidebar}
                title={title}
                description={description}
              />
            }
          />
          <Route
            path='/feedback'
            element={
              <FeedbackList
                handleArrowClick={handleArrowClick}
                isArrowActive={isArrowActive}
                sidebarWidth={sidebarWidth}
                showBigSidebar={showBigSidebar}
                title={title}
                description={description}
              />
            }
          />
          <Route
            path='/politics'
            element={
              <PrivacyPolicy
                handleArrowClick={handleArrowClick}
                isArrowActive={isArrowActive}
                sidebarWidth={sidebarWidth}
                showBigSidebar={showBigSidebar}
              />
            }
          />
          <Route path='*' element={<ErrorPage />} />
        </Routes>

        <Outlet />
      </div>
    </>
  );
}

export default App;
