import React, { useEffect } from "react";
import { Link, useNavigate, useParams, Navigate } from "react-router-dom";
import "./ServicesDetailedPageList.scss";
import ServicesDetailedPageItem from "./ServicesDetailedPageItem";
import { Helmet } from "react-helmet";

function ServicesDetailedPageList({
  handleArrowClick,
  isArrowActive,
  servicesData,
  setSelectServices,
  servicesPageTitle,
}) {
  const { id } = useParams();
  const selectServices = Array(
    servicesData.services.find((caseItem) => caseItem.ID === id)
  );

  useEffect(
    () => {
      if (selectServices) {
        setSelectServices(selectServices);
      }
    },
    [
      // selectServices, setSelectServices
    ]
  );

  const navigate = useNavigate();
  const goBack = () => {
    navigate(-1);
  };
  if (!selectServices) {
    return <Navigate to='*' />;
  }

  return (
    <div className='ServicesDetailedPageList'>
      <Helmet>
        <title>{selectServices[0].meta_tags.HEADER}</title>
        <meta
          name='description'
          content={selectServices[0].meta_tags.DESCRIPTION}
        />
        <meta name='keywords' content={selectServices[0].meta_tags.KEYWORDS} />
      </Helmet>
      <section className='left-section'>
        {" "}
        <Link onClick={goBack} className='goBackBtn'>
          Назад
        </Link>
        <div className='left-section-container'>
          <div className='title-container'>
            <h1 className='page-title'>{servicesPageTitle}</h1>
          </div>
          <Link to={"/feedback"} className='talk'>
            Обсудить проект
          </Link>{" "}
        </div>
        <div className='arrow-container'>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            width='18'
            height='18'
            viewBox='0 0 18 18'
            fill='none'
            stroke='#232323'
            className={`arrow ${isArrowActive ? "active" : ""}`}
            onClick={handleArrowClick}
          >
            <path
              d='M14 4L4 14M4 14L4 4.4M4 14L13.6 14'
              strokeLinecap='square'
            />
          </svg>
        </div>
      </section>
      <section className='right-section'>
        {selectServices.map((services) => (
          <ServicesDetailedPageItem key={services.ID} services={services} />
        ))}
      </section>
    </div>
  );
}

export default ServicesDetailedPageList;
