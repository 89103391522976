import React, { useEffect } from 'react'

const FeedbackFormInBitrix = () => {
    useEffect(() => {
        const script = document.createElement('script')
        script.src = 'https://cdn-ru.bitrix24.ru/b839921/crm/form/loader_107.js'
        script.async = true
        script.setAttribute('data-b24-form', 'inline/107/mr2210')
        script.setAttribute('data-skip-moving', 'true')

        const formContainer = document.querySelector('.form-container')
        if (formContainer) {
            formContainer.appendChild(script)
        }

        return () => {
            if (formContainer) {
                formContainer.removeChild(script)
            }
        }
    }, [])

    return (
        <div
            className='form-container'
            style={{ width: '100%', borderRadius: '40px' }}>
            {/* Блок для формы, скрипт сам найдет этот блок по атрибутам и вставит форму */}
        </div>
    )
}

export default FeedbackFormInBitrix
