import React from "react";
import "./PortfolioItem.scss";
import { Link } from "react-router-dom";
function PortfolioItem({ portfolioData, filterText }) {
  const pathPrefix = window.location.pathname.split("/")[1];
  const canNavigate = portfolioData.CONTENT_CHECK;
  const isPortfolioPage = pathPrefix === "portfolio";
  return canNavigate ? (
    <Link
      to={`/${portfolioData.LINK ? portfolioData.LINK : pathPrefix}/${
        portfolioData.ID
      }`}
      className='PortfolioItem-card'
      style={{ background: portfolioData.BG_COLOR }}
    >
      {portfolioData.IMG && (
        <div className='card-img-container'>
          <img src={portfolioData.IMG} alt='' />
        </div>
      )}
      {portfolioData.HASHTAG && (
        <div
          className='card-hashtag-container'
          style={{ color: portfolioData.TEXT_COLOR }}
        >
          <div
            className='card-hashtag'
            style={{ color: portfolioData.TEXT_COLOR }}
          >
            {portfolioData.HASHTAG}
          </div>
        </div>
      )}
      {portfolioData.NAME && (
        <div className='card-title-container'>
          <h1
            className='card-title'
            style={{ color: portfolioData.TEXT_COLOR }}
          >
            {portfolioData.NAME}
          </h1>
        </div>
      )}

      {portfolioData.DATE && (
        <p className='cardDate' style={{ color: portfolioData.TEXT_COLOR }}>
          {portfolioData.DATE}
        </p>
      )}

      {portfolioData.DESCR && (
        <p
          className='cardDescription'
          style={{ color: portfolioData.TEXT_COLOR }}
        >
          {portfolioData.DESCR}
        </p>
      )}
    </Link>
  ) : (
    <div
      className='PortfolioItem-card'
      style={{ background: portfolioData.BG_COLOR }}
    >
      {portfolioData.IMG && (
        <div className='card-img-container'>
          <img src={portfolioData.IMG} alt='' />
        </div>
      )}
      {portfolioData.HASHTAG && (
        <div
          className='card-hashtag-container'
          style={{ color: portfolioData.TEXT_COLOR }}
        >
          <div
            className='card-hashtag'
            style={{ color: portfolioData.TEXT_COLOR }}
          >
            {portfolioData.HASHTAG}
          </div>
        </div>
      )}
      {portfolioData.NAME && (
        <div className='card-title-container'>
          <h1
            className='card-title'
            style={{ color: portfolioData.TEXT_COLOR }}
          >
            {portfolioData.NAME}
          </h1>
        </div>
      )}

      {portfolioData.DATE && (
        <p className='cardDate' style={{ color: portfolioData.TEXT_COLOR }}>
          {portfolioData.DATE}
        </p>
      )}

      {portfolioData.DESCR && (
        <p
          className='cardDescription'
          style={{ color: portfolioData.TEXT_COLOR }}
        >
          {portfolioData.DESCR}
        </p>
      )}
    </div>
  );
}

export default PortfolioItem;
