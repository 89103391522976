import React from 'react'
import './MainItem.scss'
import { Link } from 'react-router-dom'
function MainItem({ mainData }) {
    const pathPrefix = window.location.pathname.split('/')[1]
    const isPortfolioPage = pathPrefix === 'portfolio'
    return (
        <Link
            to={`/${mainData.LINK ? mainData.LINK : pathPrefix}/${mainData.ID}`}
            className='MainItem-card'
            style={{ background: mainData.BG_COLOR }}>
            {mainData.IMG && (
                <div className='card-img-container'>
                    <img src={mainData.IMG} alt='' />
                </div>
            )}
            {mainData.HASTAG && isPortfolioPage && (
                <div className='card-hashtag-container'>
                    <div
                        className='card-hashtag'
                        style={{ color: mainData.TEXT_COLOR }}>
                        {mainData.HASTAG}
                    </div>
                </div>
            )}
            {mainData.NAME && !isPortfolioPage && (
                <div className='card-title-container'>
                    <h1
                        className='card-title'
                        style={{ color: mainData.TEXT_COLOR }}>
                        {mainData.NAME}
                    </h1>
                </div>
            )}

            {mainData.DATE && (
                <p className='cardDate' style={{ color: mainData.TEXT_COLOR }}>
                    {mainData.DATE}
                </p>
            )}

            {mainData.DESCR && (
                <p
                    className='cardDescription'
                    style={{ color: mainData.TEXT_COLOR }}>
                    {mainData.DESCR}
                </p>
            )}
        </Link>
    )
}

export default MainItem
