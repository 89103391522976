import React, { useEffect, useState } from 'react'
import './CareerItem.scss'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'
function CareerItem({ careerData }) {
    // const [isHovered, setIsHovered] = useState(false)

    const cardStyle = {
        color: careerData.TEXT_COLOR,
        backgroundColor: careerData.BG_COLOR,
        // filter: isHovered ? 'grayscale(0%)' : 'grayscale(100%)',
        transition: 'filter 0.5s',
    }

    console.log(careerData, 'careerData')

    return (
        <>
            {' '}
            <Helmet>
                <title>{careerData.meta_tags.HEADER}</title>
                <meta
                    name='description'
                    content={careerData.meta_tags.DESCRIPTION}
                />
                <meta name='keywords' content={careerData.meta_tags.KEYWORDS} />
            </Helmet>{' '}
            <Link
                to={`/career/${careerData.ID}`}
                className='CareerItem-card'
                style={cardStyle}
                // onMouseEnter={() => setIsHovered(true)}
                // onMouseLeave={() => setIsHovered(false)}
            >
                <div className='date'>{careerData.DATE}</div>
                <div className='card-title'>{careerData.NAME}</div>
            </Link>
        </>
    )
}

export default CareerItem
