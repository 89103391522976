import React, { useEffect, useRef, useState } from 'react'
import './SupportItem.scss'
import { InputMask } from 'primereact/inputmask'
function SupportItem() {
    const [company, setCompany] = useState('')
    const [inn, setInn] = useState('')
    const [selectedOption, setSelectedOption] = useState('')
    const [name, setName] = useState('')
    const [phone, setPhone] = useState('')
    const [email, setEmail] = useState('')
    const [comment, setComment] = useState('')
    const [showPopup, setShowPopup] = useState(false)
    const [errorPopup, setErrorPopup] = useState(false)

    const popupRef = useRef()

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (popupRef.current && !popupRef.current.contains(event.target)) {
                setShowPopup(false)
            }
        }

        document.addEventListener('mousedown', handleClickOutside)
        return () => {
            document.removeEventListener('mousedown', handleClickOutside)
        }
    }, [popupRef])

    const openPopup = () => {
        setShowPopup(true)
        setTimeout(() => {
            setShowPopup(false)
        }, 3000)
    }
    const handleCompanyChange = (e) => {
        if (e.target.value.length <= 50) {
            setCompany(e.target.value)
        } else {
            return
        }
    }

    const handleInnChange = (e) => {
        if (e.target.value.length <= 10) {
            setInn(e.target.value)
        } else {
            return
        }
    }
    const handleNameChange = (e) => {
        if (e.target.value.length <= 50) {
            setName(e.target.value)
        } else {
            return
        }
    }
    const handlePhoneChange = (e) => {
        setPhone(e.target.value)
    }
    const handleEmailChange = (e) => {
        if (e.target.value.length <= 50) {
            setEmail(e.target.value)
        } else {
            return
        }
    }
    const handleCommentChange = (e) => {
        if (e.target.value.length <= 60) {
            setComment(e.target.value)
        } else {
            return
        }
    }
    const handleOptionChange = (e) => {
        setSelectedOption(e.target.value)
    }
    const handleSubmit = async (e) => {
        e.preventDefault()
        if (
            !company ||
            !inn ||
            !selectedOption ||
            !name ||
            !phone ||
            !email ||
            !comment
        ) {
            alert('Заполните все поля!')
            return
        }

        try {
            const response = await fetch('https://example.com/api/form-data', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json;charset=utf-8',
                },
                body: JSON.stringify(
                    company,
                    inn,
                    selectedOption,
                    name,
                    phone,
                    email,
                    comment
                ),
            })

            console.log('Данные формы отправлены:', response.data)
        } catch (error) {
            console.error('Ошибка при отправке данных формы:', error)
        }
    }
    // const handleSubmit = (e) => {
    //     e.preventDefault()
    //     if (
    //         !company ||
    //         !inn ||
    //         !selectedOption ||
    //         !name ||
    //         !phone ||
    //         !email ||
    //         !comment
    //     ) {
    //         setErrorPopup(true)
    //         setTimeout(() => {
    //             setErrorPopup(false)
    //         }, 3000)
    //         return
    //     }
    // }
    // const popup = () => {
    //     setShowPopup(true)
    //     setTimeout(() => {
    //         setShowPopup(false)
    //     }, 3000)
    // }

    return (
        <div className='SupportItem-card'>
            {/* {errorPopup && (
                <div className=errorPopup}>
                    Заполните все поля и выберите тип происшествия
                </div>
            )} */}
            <h1 className='support-title'>Форма тех.поддержки</h1>
            <form onSubmit={handleSubmit} className='support-form'>
                <div className='input-section'>
                    <input
                        type='text'
                        placeholder='Компания*'
                        className='support-input'
                        value={company}
                        onChange={handleCompanyChange}
                        required
                    />

                    <div className='input-inn'>
                        <input
                            type='text'
                            inputMode='numeric'
                            placeholder='ИНН'
                            className='support-input input-inn'
                            value={inn}
                            onChange={handleInnChange}
                            required
                        />
                        <svg className='input-inn-icon' onClick={openPopup}>
                            <use xlinkHref='/sprite.svg#questions-icon' />
                        </svg>

                        {showPopup ? (
                            <span className='popup' ref={popupRef}>
                                Введите ИНН юр. лица, состоящий из 10 цифр
                            </span>
                        ) : null}
                        <div className='input-inn-text'>
                            Пожалуйста введите ИНН компании чтобы разблокировать
                            типы происшествий
                        </div>
                    </div>
                </div>

                <div className='event-btns'>
                    <div class='form-radio-btn'>
                        <input
                            id='radio-1'
                            type='radio'
                            name='option'
                            value='Задать вопрос'
                            className='support-input'
                            checked={selectedOption === 'Задать вопрос'}
                            onChange={handleOptionChange}
                            required
                        />
                        <label for='radio-1'>Задать вопрос</label>
                    </div>
                    <div class='form-radio-btn'>
                        <input
                            id='radio-2'
                            type='radio'
                            name='option'
                            value='Задать вопрос в бухгалтерию'
                            className='support-input'
                            checked={
                                selectedOption === 'Задать вопрос в бухгалтерию'
                            }
                            onChange={handleOptionChange}
                            required
                        />
                        <label for='radio-2'>Задать вопрос в бухгалтерию</label>
                    </div>
                    <div class='form-radio-btn'>
                        <input
                            id='radio-3'
                            type='radio'
                            name='option'
                            value='Не работает сайт'
                            className='support-input'
                            checked={selectedOption === 'Не работает сайт'}
                            onChange={handleOptionChange}
                            required
                        />
                        <label for='radio-3'>Не работает сайт</label>
                    </div>
                    <div class='form-radio-btn'>
                        <input
                            id='radio-4'
                            type='radio'
                            name='option'
                            value='Ошибка внутри сайта'
                            className='support-input'
                            checked={selectedOption === 'Ошибка внутри сайта'}
                            onChange={handleOptionChange}
                            required
                        />
                        <label for='radio-4'>Ошибка внутри сайта</label>
                    </div>
                    <div class='form-radio-btn'>
                        <input
                            id='radio-5'
                            type='radio'
                            name='option'
                            value='Доступ'
                            className='support-input'
                            checked={selectedOption === 'Доступ'}
                            onChange={handleOptionChange}
                            required
                        />
                        <label for='radio-5'>Доступ</label>
                    </div>
                </div>

                <div className='input-section'>
                    <input
                        type='text'
                        placeholder='Фио*'
                        className='support-input'
                        value={name}
                        onChange={handleNameChange}
                        required
                    />
                    {/* <input
                        type='tel'
                        inputMode='tel'
                        placeholder='Телефон*'
                        className=support-input}
                        value={phone}
                        onChange={handlePhoneChange}
                        required
                    /> */}
                    <InputMask
                        id='phone'
                        required
                        mask='+7 (999) 999-9999'
                        className='support-input'
                        onChange={handlePhoneChange}
                        value={phone}
                        placeholder='+7 (XXX) XXX-XXXX'></InputMask>
                    <input
                        type='email'
                        inputMode='email'
                        placeholder='Электронная почта*'
                        className='support-input'
                        value={email}
                        onChange={handleEmailChange}
                    />
                    <textarea
                        placeholder='Введите комментарий*'
                        className='support-area'
                        value={comment}
                        onChange={handleCommentChange}
                    />
                </div>
                <button
                    type='submit'
                    className='support-btn'
                    // onClick={setErrorPopup}
                >
                    <p className='support-btn-text'>Отправить</p>
                </button>
            </form>
        </div>
    )
}

export default SupportItem
